export function initLanguageSelect() {
  document.addEventListener("DOMContentLoaded", function () {
    const button = document.querySelector("button[aria-haspopup='listbox']");
    const languageOptions = document.getElementById("language-options");
    const selectedLanguage = document.getElementById("selected-language");

    button.addEventListener("click", function () {
      languageOptions.classList.toggle("hidden");
      const expanded = button.getAttribute("aria-expanded") === "true" || false;
      button.setAttribute("aria-expanded", !expanded);
    });

    document.querySelectorAll("#language-options li").forEach((option) => {
      option.addEventListener("click", function () {
        const selectedLang = option.getAttribute("data-lang");

        // Redirect based on the selected language
        if (selectedLang === "es") {
          window.location.href = "/";
        } else if (selectedLang === "fr") {
          window.location.href = "/fr/";
        }
      });
    });
  });
}
