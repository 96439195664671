export function initLanguageSwitcher() {
  document.addEventListener("DOMContentLoaded", function () {
    const languageSwitcher = document.getElementById("language-switcher");

    if (!languageSwitcher) {
      console.log("Language switcher element not found.");
      return; // Exit if the element is not found
    }

    const dismissButton = document.getElementById("dismiss-button");

    // Check if the message was dismissed
    if (localStorage.getItem("dismissedLanguageSwitcher") !== "true") {
      languageSwitcher.style.display = "flex";
    }

    // Handle dismiss button click
    dismissButton.addEventListener("click", function () {
      languageSwitcher.style.display = "none";
      localStorage.setItem("dismissedLanguageSwitcher", "true");
    });
  });
}
