import "$styles/index.css";
import "$styles/syntax-highlighting.css";

import components from "$components/**/*.{js,jsx,js.rb,css}";

import { initLanguageSwitcher } from "./language_switcher";
import { initLanguageSelect } from "./language_select";
import { initMobileMenu } from "./mobile_menu";

initLanguageSwitcher();
initLanguageSelect();
initMobileMenu();

console.info("Bridgetown is loaded!");
