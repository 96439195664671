export function initMobileMenu() {
  document.addEventListener("DOMContentLoaded", function () {
    const menuOpenButton = document.getElementById("mobile-menu-open");
    const menuCloseButton = document.getElementById("mobile-menu-close");
    const mobileMenu = document.getElementById("mobile-menu");
    const backdrop = document.getElementById("backdrop");

    menuOpenButton.addEventListener("click", function () {
      mobileMenu.classList.remove("hidden");
    });

    [menuCloseButton, backdrop].forEach((el) => {
      el.addEventListener("click", function () {
        mobileMenu.classList.add("hidden");
      });
    });
  });
}
